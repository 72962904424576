import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Logo from './Logo.js'
import Error from './Error';

export default function Example() {

  useEffect(() => {
    document.title = "Sign in to Chat With Medical Textbooks"; 
    const descriptionMeta = document.querySelector('meta[name="description"]');
    if (descriptionMeta) {
      descriptionMeta.setAttribute('content', 'Sign in to chat with medical and healthcare textbooks'); // Set the page description
    }
  }, []);

  const [formData, setFormData] = useState({
    email_address: '',
    password: ''
  });
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {

        let baseUrl;

        if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
          console.log("Running on localhost");
          baseUrl = 'http://localhost:8081/authenticate';
        } else {
          // Running with a public IP address or domain
          baseUrl = 'https://api.chatmd.com/authenticate'; // Replace "example.com" with your publicly accessible domain or IP address
        }
        const response = await axios.post(baseUrl, { ...formData }, {
            withCredentials: true,
        });
  
        if (response.status === 200) {
            window.location.href = '/ask';
            localStorage.setItem('userProfile', JSON.stringify(response.data));
        } else {
            throw new Error('Something went wrong');
        }
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
                if (error.response.data && error.response.data.detail) {
                    console.error(`Error ${error.response.status}:`, error.response.data.detail);
                    setError(error.response.data.detail);
                } else {
                    const errorMessage = error.response.status === 401 ? 'Unauthorized access' : 'Forbidden access';
                    setError(errorMessage);
                }
            } else if (error.response.status === 422) {
                if (error.response.data && error.response.data.detail) {
                    console.error('Error 422:', error.response.data.detail);
                    setError(error.response.data.detail);
                } else {
                    setError('Request Error');
                }
            } else if (error.response.data && error.response.data.detail) {
                console.error(`Error ${error.response.status}:`, error.response.data.detail);
                setError(error.response.data.detail);
            } else {
                setError('Request Error');
            }
        } else {
            setError('Request Error');
        }
        console.error('Request Error:', error);
    }
};
  

    return (
      <>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="flex justify-center items-center h-10">
            <Logo className="logo" width={12} height={12} />
            </div>
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-blue-500">
              Sign in to your account
            </h2>
            {error && <Error error={error} />}
          </div>
  
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6"  onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email_address" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email_address"
                    name="email_address"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    onChange={(e) => setFormData({ ...formData, email_address: e.target.value })}
                  />
                </div>
              </div>
  
              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  {/* <div className="text-sm">
                    <a href="/forgotpassword" className="font-semibold text-indigo-400 hover:text-indigo-300">
                      Forgot password?
                    </a>
                  </div> */}
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  />
                </div>
              </div>
  
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-blue-500 bg-opacity-88 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              </div>
              {/* <p className="mt-10 text-center text-sm text-gray-500">
                    Don't have an account?{' '}
                    <a href="/signup" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                    Sign up here!
                    </a>
                </p> */}
            </form>
          </div>
        </div>
      </>
    )
  }
  