import { useEffect } from 'react';

const Signout = () => {
    useEffect(() => {

      document.cookie = "cmda=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.chatmd.com";
      // Redirect to '/'
      window.location.href = '/';
    }, []);
  
    return null; // Or you can return a loading indicator or a message briefly before redirecting
  };

// const Signout = () => {
//   console.log("In the signout component");
//   useEffect(() => {

//     let baseUrl;

//     if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
//         console.log("Running on localhost");
//         baseUrl = 'http://localhost:8081/signout';
//     } else {
//         // Running with a public IP address or domain
//         console.log("Running on AWS");
//         baseUrl = 'https://api.chatmd.com/signout'; // Replace "example.com" with your publicly accessible domain or IP address
//     }

//     const signout = async () => {
//       try {
//         await fetch(baseUrl, {
//           method: 'POST',
//         });

//         // Redirect to '/'
//         window.location.href = '/';
//       } catch (error) {
//         console.error('Error signing out:', error);
//       }
//     };

//     signout();
//     console.log("Called signout()")
//   }, []);

//   return null; // Or you can return a loading indicator or a message briefly before redirecting
// };
  
export default Signout;
