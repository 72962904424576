import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Error from './Error';
import Logo from './Logo.js'

export default function Waitlist() {

    useEffect(() => {
        document.title = "Interested in Chatting With Medical Records and Textbooks?"; 
        const descriptionMeta = document.querySelector('meta[name="description"]');
        if (descriptionMeta) {
          descriptionMeta.setAttribute('content', 'Interested in chatting with medical records and textbooks'); // Set the page description
        }
      }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
    };
    

    const [formData, setFormData] = useState({
        email_address: '',
        full_name: '',
        reason: '',
        hp: '' // Add 'hp' field to the initial state
      });
      const [hp, setHp] = useState(''); 
      const [error, setError] = useState(null);
    
      const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {
            let baseUrl;

            if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
                console.log("Running on localhost");
                baseUrl = 'http://localhost:8081/waitlist';
            } else {
            // Running with a public IP address or domain
                baseUrl = 'https://api.chatmd.com/waitlist'; // Replace "example.com" with your publicly accessible domain or IP address
            }
            const response = await axios.post(baseUrl, { ...formData, hp }, {
                withCredentials: true,
            });
      
            if (response.status === 200) {
                window.location.href = '/interested/success';
            } else {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    if (error.response.data && error.response.data.detail) {
                        console.error(`Error ${error.response.status}:`, error.response.data.detail);
                        setError(error.response.data.detail);
                    } else {
                        const errorMessage = error.response.status === 401 ? 'Unauthorized access' : 'Forbidden access';
                        setError(errorMessage);
                    }
                } else if (error.response.status === 422) {
                    if (error.response.data && error.response.data.detail) {
                        console.error('Error 422:', error.response.data.detail);
                        setError(error.response.data.detail);
                    } else {
                        setError('Request Error');
                    }
                } else if (error.response.data && error.response.data.detail) {
                    console.error(`Error ${error.response.status}:`, error.response.data.detail);
                    setError(error.response.data.detail);
                } else {
                    setError('Request Error');
                }
            } else {
                setError('Request Error');
            }
            console.error('Request Error:', error);
        }
    };
    
      

      const handleHpChange = (e) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          hp: e.target.value
        }));
      };

    return (
      <>
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="flex justify-center items-center h-10">
                <Logo className="logo" width={12} height={12} />
            </div>
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-blue-500">
              Interested in chatting with your medical records?
            </h2>
            {error && <Error error={error} />}

          </div>
  
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="full_name" className="block text-sm font-medium leading-6 text-gray-900">
                  Full Name
                </label>
                <div className="mt-2">
                  <input
                    id="full_name"
                    name="full_name"
                    type="text"
                    onChange={handleChange}
                    required
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
  
              <div>
                <label htmlFor="email_address" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email_address"
                    name="email_address"
                    type="email"
                    onChange={handleChange}
                    required
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
  
              <div>
                <label htmlFor="reason" className="block text-sm font-medium leading-6 text-gray-900">
                  Reason for interest
                </label>
                <div className="mt-2">
                  <textarea
                    id="reason"
                    name="reason"
                    rows={5}
                    required
                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    onChange={handleChange}
                  />
                </div>
              </div>



              <input
                type="hidden"
                name="hp"
                value={formData.hp} // Use formData.hp as the value
                onChange={handleHpChange} // Update the 'hp' value in formData
              />

  
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-blue-500 bg-opacity-88 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Submit
                </button>
              </div>

            </form>
          </div>
        </div>
      </>
    )
}
