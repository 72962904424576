import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Error from './Error';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

export default function Activation() {

  const { activation_key } = useParams();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const activateAccount = async () => {
        try {
            const response = await axios.get(`http://localhost:8081/activate/${activation_key}`);
            if (response.status === 200) {
                setSuccess(true);
            } else {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    if (error.response.data && error.response.data.detail) {
                        console.error(`Error ${error.response.status}:`, error.response.data.detail);
                        setError(error.response.data.detail);
                    } else {
                        const errorMessage = error.response.status === 401 ? 'Unauthorized access' : 'Forbidden access';
                        setError(errorMessage);
                    }
                } else if (error.response.status === 422) {
                    if (error.response.data && error.response.data.detail) {
                        console.error('Error 422:', error.response.data.detail);
                        setError(error.response.data.detail);
                    } else {
                        setError('Request Error');
                    }
                } else if (error.response.data && error.response.data.detail) {
                    console.error(`Error ${error.response.status}:`, error.response.data.detail);
                    setError(error.response.data.detail);
                } else {
                    setError('Request Error');
                }
            } else {
                setError('Request Error');
            }
            console.error('Request Error:', error);
        }
    };

    activateAccount();
}, [activation_key]);


  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
          alt="Your Company"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Account activation
        </h2>
        {error && <Error error={error} />}
        {success && (
          <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">Activation successful!</h3>
                <div className="mt-2 text-sm text-green-700">
                  <p>Your account has been activated. You can now login.</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
