
import { useState, useRef, useEffect } from 'react'
import { ChatBubbleLeftEllipsisIcon, TagIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import ProfilePic from './ProfilePic';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Feed({ messages }) {

  const bottomRef = useRef(null);
  const [userScrolled, setUserScrolled] = useState(false);

  useEffect(() => {
    if (bottomRef.current && !userScrolled) {
      const bottomVisible = isElementBottomVisible(bottomRef.current);
      if (!bottomVisible) {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [messages, userScrolled]);

  const handleScroll = () => {
    setUserScrolled(true);
  };

  const isElementBottomVisible = (element) => {
    const elementRect = element.getBoundingClientRect();
    return elementRect.bottom <= window.innerHeight;
  };

  return (
    <div onScroll={handleScroll} className="flow-root">
      <ul role="list" className="-mb-8">
      {messages.map((messagesItem, messagesItemIdx) => (
        <li key={messagesItem.id}>
          <div className="relative pb-8">
            {messagesItemIdx !== messages.length - 1 ? (
              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
            ) : null}
            <div className="relative flex items-start space-x-3">
              {messagesItem.type === 'message' ? (
                <>
                  <div className="relative">
                    <ProfilePic initials={messagesItem.person.initials} />
                  </div>
                  <div className="min-w-0 flex-1">
                    <div className="mt-2 text-sm text-gray-700">
                      <p>{messagesItem.message}</p>
                    </div>
                  </div>
                </>
              ) : messagesItem.type === 'assignment' ? (
                <>
                  <div>
                    <div className="relative px-1">
                      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 ring-8 ring-white">
                        <UserCircleIcon className="h-5 w-5 text-blue-500" aria-hidden="true" />
                      </div>
                    </div>
                  </div>
                  <div className="min-w-0 flex-1 py-1.5">
                    <div className="text-sm text-gray-500">
                      {/* <a href={messagesItem.person.href} className="font-medium text-gray-900">
                        {messagesItem.person.name}
                      </a>{' '}
                      assigned{' '}
                      <a href={messagesItem.assigned.href} className="font-medium text-gray-900">
                        {messagesItem.assigned.name}
                      </a>{' '} */}
                      <span className="whitespace-nowrap">{messagesItem.date}</span>
                    </div>
                  </div>
                </>
              ) : messagesItem.type === 'tags' ? (
                <>
                  <div>
                    <div className="relative px-1">
                      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 ring-8 ring-white">
                        <TagIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                      </div>
                    </div>
                  </div>
                  <div className="min-w-0 flex-1 py-0">
                    <div className="text-sm leading-8 text-gray-500">
                      {/* <span className="mr-0.5">
                        <a href={messagesItem.person.href} className="font-medium text-gray-900">
                          {messagesItem.person.name}
                        </a>{' '}
                        added tags
                      </span>{' '} */}
                      <span className="whitespace-nowrap">{messagesItem.date}</span>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {messagesItemIdx === messages.length - 1 && <div ref={bottomRef}></div>}
        </li>
      ))}
      </ul>
    </div>
  )
}
