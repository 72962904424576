import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './Home';
import Signin from './Signin.js';
import Signout from './Signout.js';
import Signup from './Signup'
import Activation from './Activation.js'
import Marketing from './Marketing.js'
import Ask from './Ask.js'
import NotFound from './NotFound.js'
import Waitlist from './Waitlist';
import WaitlistSuccess from './WaitlistSuccess';

import SignupSuccess from './SignupSuccess'
import isAuthenticated from './util/auth.js';

const AppRouter = () => {

  const hasCmda = isAuthenticated();

  return (
    <Router>
      <Routes>
      <Route path="/" element={<Marketing />} />
        <Route path="/ask" element={hasCmda ? <Ask /> : <Navigate to="/signin" replace />}/>
        {/* <Route path="/ask2" element={<Ask />}/> */}

        <Route path="/signin" element={<Signin />} />
        <Route path="/signout" element={<Signout />} />
        <Route path="/interested" element={<Waitlist />} />
        <Route path="/interested/success" element={<WaitlistSuccess />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        {/* <Route path="/signup/success" element={<SignupSuccess />} /> */}
        <Route path="/activate/:activation_key" element={<Activation />} />

        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
