

export default function ProfilePic({initials}) {
    return (
      <>  
        <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-blue-500">
          <span className="font-medium leading-none text-white">{initials}</span>
        </span>
      </>
    )
  }