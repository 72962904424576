import { Fragment, useState, useRef, useEffect } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Feed from './Feed.js'
import ProfilePic from './ProfilePic.js'
import Logo from './Logo.js'

const navigation = [
  { name: 'ChatMD', href: '/ask', current: true },
]
const userNavigation = [
//   { name: 'Your Profile', href: '#' },
//   { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '/signout' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Home() {
    const feedContainerRef = useRef(null); // Ref for the feed container
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false); // New loading state

    const userProfileData = localStorage.getItem('userProfile');
    const userProfile = JSON.parse(userProfileData);
    const initials = userProfile.full_name
        .split(' ')
        .map(namePart => namePart.charAt(0))
        .join('');

    const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

    const handleSubmit = async (event) => {
        event.preventDefault();
        const currentMessage = message;
        setMessage(''); // Clear the input field immediately after the submit button is clicked
    
        const previousResponse = messages[messages.length - 1];
        const firstMessage = messages[0];
        const messageObject = {
            id: getRandomInt(1, 50000),
            type: 'message',
            person: { name: 'User Name' },
            message: currentMessage,
            date: new Date().toLocaleString(),
            previous_response: previousResponse,
            first_message: firstMessage
        };
    
        messageObject.person.initials = initials;
      
        setMessages([...messages, messageObject]);
      
        setLoading(true);
      
        try {
            let baseUrl;
    
            if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
                console.log("Running on localhost");
                baseUrl = 'http://localhost:8081/chat';
            } else {
                console.log("Running on AWS");
                baseUrl = 'https://api.chatmd.com/chat';
            }
    
            const response = await fetch(baseUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(messageObject),
                credentials: 'include',
            });
        
            const data = await response.json();
            console.log('Response:', data);
        
            // Add server response as new message
            setMessages(prevMessages => [...prevMessages, { ...data }]);
        } catch (error) {
            console.error('Error:', error);
            setMessage(currentMessage); // Restore the message in case of error
        } finally {
            setLoading(false);
        }
    };
    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     const currentMessage = message;
    //     setMessage(''); // Clear the input field immediately after the submit button is clicked

    //     const messageText = document.getElementById('message').value;
    //     const previousMessage = messages[messages.length - 1];
    //     const messageObject = {
    //     id: getRandomInt(1, 50000),
    //     type: 'message',
    //     person: { name: 'User Name' },
    //     message: messageText,
    //     date: new Date().toLocaleString(),
    //     previous_message: previousMessage
    //     };

    //     // Create initials from the name
        
    //     messageObject.person.initials = initials;
    
    //     // Optimistically update state with user's message
    //     setMessages([...messages, messageObject]);
    
    //     setLoading(true);
    
    //     try {
    //     let baseUrl;

    //     if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    //         console.log("Running on localhost");
    //         baseUrl = 'http://localhost:8081/chat';
    //     } else {
    //         // Running with a public IP address or domain
    //         console.log("Running on AWS");
    //         baseUrl = 'https://api.chatmd.com/chat'; // Replace "example.com" with your publicly accessible domain or IP address
    //     }

    //     const response = await fetch(baseUrl, {
    //         method: 'POST',
    //         headers: {
    //         'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(messageObject),
    //         credentials: 'include',
    //     });
    
    //     const data = await response.json();
    //     console.log('Response:', data);
    
    //     // Add server response as new message
    //     setMessages(prevMessages => [...prevMessages, { ...data }]);
    //     } catch (error) {
    //     console.error('Error:', error);
    //     } finally {
    //     setLoading(false);
    //     }
    
    //     // setMessage(''); // Clear the input field after submitting the message
    // };


    useEffect(() => {
        // Scroll to the newest message whenever messages change
        if (feedContainerRef.current) {
            feedContainerRef.current.scrollTop = feedContainerRef.current.scrollHeight;
        }
        }, [messages]);

    useEffect(() => {
        document.title = "Chat With Medical and Healthcare Textbooks"; 
        const descriptionMeta = document.querySelector('meta[name="description"]');
        if (descriptionMeta) {
            descriptionMeta.setAttribute('content', 'ChatMD is an AI application to ask questions of medical and healthcare textbooks'); // Set the page description
        }
    }, []);

    // useEffect(() => {
    //     function handleResize() {
    //       if (bottomRef.current) {
    //         bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    //       }
    //     }
    
    //     window.addEventListener('resize', handleResize);
    //     return () => {
    //       window.removeEventListener('resize', handleResize);
    //     };
    //   }, []);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="min-h-full">
        <Disclosure as="nav" className="border-b border-gray-200 bg-white">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div className="flex flex-shrink-0 items-center">
                        <Logo className="logo" width={12} height={12} />
                    </div>
                    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'border-blue-500 text-gray-900'
                              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    {/* <button
                      type="button"
                      className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                          <span className="sr-only">Open user menu</span>
                            <ProfilePic initials={initials} />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <div className="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 pb-3 pt-2">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'border-blue-500 bg-blue-50 text-blue-700'
                          : 'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800',
                        'block border-l-4 py-2 pl-3 pr-4 text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-200 pb-3 pt-4">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <ProfilePic initials={initials} />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">{userProfile.full_name}</div>
                    </div>
                    <button
                      type="button"
                      className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">View notifications</span>
                      {/* <BellIcon className="h-6 w-6" aria-hidden="true" /> */}
                    </button>
                  </div>
                  <div className="mt-3 space-y-1">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <div className="py-10">
            <div className="mx-auto max-w-3xl">{/* Content goes here */}
                <div className="flex flex-col min-h-screen">
                    <main className="flex-grow">

                        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                            
                            
                        <div ref={feedContainerRef}>
                            <Feed messages={messages}/>            
                        </div>
                        
                        
                        </div>
                    </main>
                </div>
                <div className="fixed bottom-0 left-0 w-full bg-white shadow-lg py-4 px-4 sm:px-6">

                <form className="mx-auto max-w-3xl">
                    <div>
                        <div className="mt-1">
                        <textarea
                            id="message"
                            name="message"
                            rows={3}
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Ask about symptoms, a condition, nutrition or anything else related to medicine, dentistry, etc."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        </div>
                    </div>
                    <div className="mt-4">
                        <button
                        type="submit"
                        className="bg-blue-500 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        disabled={loading}
                        onClick={handleSubmit}
                        >
                        {loading ? (
                            <>
                            <svg
                                className="animate-spin h-5 w-5 mr-3 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0012 20c4.411 0 8-3.589 8-8h-2c0 3.309-2.691 6-6 6-3.309 0-6-2.691-6-6H6c0 4.411 3.589 8 8 8z"
                                />
                            </svg>
                            Processing...
                            </>
                        ) : (
                            'Submit'
                        )}
                        </button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
      </div>
      </div>
    </>
  )
}
