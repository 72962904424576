import Cookies from 'js-cookie';

function isAuthenticated() {
  // Retrieve the value of the 'cmda' cookie
  const cmda = Cookies.get('cmda');

  // Return true if the 'cmda' cookie exists and is not empty, false otherwise
  return !!cmda;
}

export default isAuthenticated;
